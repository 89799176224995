<template>
  <div>
    <v-container >
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :canDelete="true"
        :items="whomItems"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :createNewItem="newItemDialog"
        toolbarTitle="To Whom-List"
        @loadCRUDList="handleLoadCRUDList"
        @emitDeleteRowItem="deleteRowItem"
        @upsertItem="upsertItem"
        :defaultSortBy="sortBy"
      >
    <template v-slot:[`additionalTools`]="{}">
      <v-col cols="2">
        <v-text-field class="warwhoms fiscalYear"
        v-model.number="criteriaState.fiscalYear"
          label="Fiscal Year"
          type="number"
          ref="fiscalYear"
          @input="criteriaChanged"
        ></v-text-field>
      </v-col>
      <v-divider
              class="mx-4" inset vertical
            ></v-divider>
      <v-col cols="2">
            <v-text-field
              type="number"
              label="Merge to FY"
              v-model.number="copyToFY"
              ></v-text-field>
              </v-col>
              <v-divider
              class="mx-4" inset vertical
            ></v-divider>
              <v-btn
              class="mx-2"
              @click="copyAll"
              text color="primary">
              Merge
            </v-btn>
            <v-divider
              class="mx-4" inset vertical
            ></v-divider>
            <v-btn
              class="mx-2"
              @click="clearAll"
              text color="primary">
              Delete all for FY
            </v-btn>
    </template>
    <template v-slot:[`editedItem`]="{}">
          <WhomsEditedItem :baseCrudKey="baseCrudKey" />
        </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import WhomsEditedItem from './WhomsEditedItem.vue';
import {
  toFiscalYear,
} from '../../../util/shared/tmc-global';

const fields = {
  _id: '',
  description: '',
  fiscalYear: '',
};

const getHeaders = (canDelete) => {
  const headers = [
    { text: 'Description', value: 'description' },
  ];
  if (canDelete) {
    headers.push({ text: 'Actions', value: 'actions', sortable: false });
  }
  return headers;
};

export default {
  name: 'WhomsList',
  components: {
    CRUDList,
    WhomsEditedItem,
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    baseCrudKey: 'warWhoms',
    headers: [],
    copyToFY: undefined,
    sortBy: [
      {
        column: 'description',
        direction: 'asc',
      },
    ],
  }),
  created() {
    // resetting the criteria
    this.setCriteria([this.baseCrudKey, {}]);
    this.initCriteria();
    this.headers = getHeaders(this.canDelete);
    this.copyToFY = toFiscalYear(new Date(this.todaysDate));
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'items',
    ]),
    ...mapGetters(['todaysDate']),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    whomItems() {
      return this.items(this.baseCrudKey);
    },
  },
  methods: {
    ...mapActions('war/whom', [
      'loadWhoms',
      'upsrtWhom',
      'delWhom',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),

    initCriteria() {
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      this.loadWhoms({
        criteria,
        fields,
        baseCrudKey,
      });
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    upsertItem(item) {
      const { baseCrudKey } = this;
      this.upsrtWhom({ item, fields, baseCrudKey });
    },
    deleteRowItem(item) {
      if (window.confirm('Are you sure you want to delete this item?')) {
        const { baseCrudKey } = this;
        this.delWhom({ item, baseCrudKey });
      }
    },
    criteriaChanged() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      this.loadWhoms({
        criteria,
        fields,
        baseCrudKey,
      });
    },
    newItemDialog() {
      const newItem = {};
      if (this.criteriaState.fiscalYear && this.criteriaState.fiscalYear !== '') {
        newItem.fiscalYear = this.criteriaState.fiscalYear;
      }
      return newItem;
    },
    async copyAll() {
      if (!window.confirm(`This will create any entries from FY ${this.criteriaState.fiscalYear} in FY ${this.copyToFY}. Continue?`)) {
        return;
      }
      const copyPromises = this.whomItems
        .map((w) => {
          const newWhom = { ...w };
          delete newWhom._id;
          newWhom.fiscalYear = this.copyToFY;
          return newWhom;
        })
        .map((w) => {
          const copyPromise = this.$store.dispatch('war/whom/upsrtWhom', { item: w, baseCrudKey: this.baseCrudKey, fields });
          return copyPromise;
        });
      await Promise.all(copyPromises);
      this.loadWhoms({ criteria: this.criteriaState, fields, baseCrudKey: this.baseCrudKey });
    },
    async clearAll() {
      if (!window.confirm(`This will DELETE all entries from FY ${this.criteriaState.fiscalYear}. Continue?`)) {
        return;
      }
      const deletePromises = this.whomItems.map((w) => {
        const delPromise = this.$store.dispatch('war/whom/delWhom', { item: w, baseCrudKey: this.baseCrudKey, fields });
        return delPromise;
      });
      await Promise.all(deletePromises);
      this.loadWhoms({ criteria: this.criteriaState, fields, baseCrudKey: this.baseCrudKey });
    },
  },
};
</script>
