<template>
  <div>
    <v-container >
      <WhomsList/>
    </v-container>
  </div>
</template>

<script>
import WhomsList from '../../../components/War/Whoms/WhomsList.vue';

export default {
  name: 'WarWhoms',
  components: {
    WhomsList,
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
  },
  methods: {
  },
};

</script>
