<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          :autofocus="true"
          label= "Description"
          v-model="editedItem.description"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label= "Fiscal Year"
          v-model="editedItem.fiscalYear"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'WhomsEditedItem',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
    ]),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    changes() {
      return this.editedItem.changes || [];
    },
  },
  methods: {
    ...mapActions('war/whom', [
      'loadWhoms',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItem',
    ]),
  },
};
</script>
